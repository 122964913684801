import "./App.css";

import { useState, useEffect, useRef, memo, useCallback } from "react";

import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import ReplayIcon from "@mui/icons-material/Replay";
import ForumIcon from "@mui/icons-material/Forum";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Badge from "@mui/material/Badge";

import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";

import DeleteIcon from "@mui/icons-material/Delete";
import InsightsIcon from "@mui/icons-material/Insights";
import SettingsIcon from "@mui/icons-material/Settings";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import ScheduleIcon from "@mui/icons-material/Schedule";

import { Authenticator } from "@aws-amplify/ui-react";
import { SignOut } from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import "@aws-amplify/ui-react/styles.css";

import TourIcon from "@mui/icons-material/Tour";
import Button from "@mui/material/Button";
import ProjectPicker from "./ProjectPicker";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import FavoriteIcon from "@mui/icons-material/Favorite";

import PhotoIcon from "@mui/icons-material/Photo";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";

import TurnedInIcon from "@mui/icons-material/TurnedIn";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import ShareIcon from "@mui/icons-material/Share";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import init from "jsriki";

import { useAtom, useAtomValue, useSetAtom } from "jotai";

import store from "store2";
import PestControlIcon from "@mui/icons-material/PestControl";
import { refreshToken, switchStage } from "./Auth";

import { Main, AppBar, DrawerHeader } from "./AppStyled";
import { MessageItem } from "./MessageItem";

import {
  labelToggleState,
  debugToggleState,
  imageToggleAtom,
  projectPickerState,
  timeFilterState,
  mergedStoriesState,
  joinStoriesState,
  reloadState,
  showSelectedStoriesState,
  selectedCategory,
  MenuCategory,
  menuCategoriesList,
} from "./JotaiAtoms";

import * as lodash from "lodash";

import GraphView from "./StoryGraph";

import { WebStory } from "./WebStory";
import { Search } from "./Search";
import { StoryLabelFilterBox, LabelsData } from "./StoryLabelFilterBox";
import { Thread } from "./Thread";
import { Settings } from "./Settings";
import log from "./logger";
import { FetchStories, getCheckedThreads } from "./FetchStories";

import * as u from "./utility";

import { useHttpPostAndProject, useStage, useLocalStorage } from "./hooks";
import { QuickSearch } from "./Search";
import { DarkStoryPreview } from "./StoryDetails";
import { People, PeopleData, PeopleControls } from "./People";

const MIN_DRAWER_WIDTH = 177;
const MAX_DRAWER_WIDTH = 400;
const DEFAULT_DRAWER_WIDTH = 177;

type RetryToastVerticalProps = {
  message: string;
  onRetry?: (e: React.MouseEvent<HTMLElement>) => void;
  onUndo?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: any;
};

function RetryToastVertical({
  message,
  onRetry,
  onUndo,
  children,
}: RetryToastVerticalProps) {
  return (
    <Stack direction={"column"} sx={{ width: "100%" }}>
      <Typography fontSize={12}>{message}</Typography>
      <Box sx={{ maxHeight: "300px", overflow: "auto" }}>{children}</Box>
      <Stack direction={"row"}>
        {onRetry && (
          <Button onClick={onRetry} size="small">
            RETRY
          </Button>
        )}
        {onUndo && (
          <Button onClick={onUndo} size="small">
            UNDO
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

type RetryToastHorizontalProps = {
  message: string;
  onRetry?: (e: React.MouseEvent<HTMLElement>) => void;
  onUndo?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: any;
};
function RetryToastHorizontal({
  message,
  onRetry,
  onUndo,
  children,
}: RetryToastHorizontalProps) {
  return (
    <Stack direction={"row"} sx={{ width: "100%" }}>
      <Stack direction={"row"}>
        <Typography fontSize={12}>{message}</Typography>
        {children}
      </Stack>
      {onRetry && (
        <Button onClick={onRetry} size="small">
          RETRY
        </Button>
      )}
      {onUndo && (
        <Button onClick={onUndo} size="small">
          UNDO
        </Button>
      )}
    </Stack>
  );
}

function ShowSelectedStoriesButton() {
  const [selected, setSelected] = useAtom(joinStoriesState);
  const [numSelected, setNumSelected] = useState(0);
  const setShowSelectedStories = useSetAtom(showSelectedStoriesState);

  useEffect(() => {
    setNumSelected(getCheckedThreads(selected).length);
  }, [selected]);

  const handleClick = () => {
    setShowSelectedStories(true);
  };

  const handleClear = () => {
    setSelected({});
  };

  return (
    <>
      <IconButton
        color="inherit"
        edge="start"
        sx={{ mr: 2 }}
        disabled={numSelected === 0}
        onClick={handleClick}
      >
        <CheckBoxIcon />
      </IconButton>
      <IconButton
        color="inherit"
        edge="start"
        sx={{ mr: 2 }}
        disabled={numSelected === 0}
        onClick={handleClear}
      >
        <DisabledByDefaultRoundedIcon />
      </IconButton>
    </>
  );
}

type JoinButtonProps = {
  onJoin: (threads: string[]) => void;
  onUndo: () => void;
  sx: any;
};

const JoinButton = ({ onJoin, onUndo, sx }: JoinButtonProps) => {
  const [selected, setSelected] = useAtom(joinStoriesState);
  const [numSelected, setNumSelected] = useState(0);
  const [httpPost, project] = useHttpPostAndProject();

  useEffect(() => {
    setNumSelected(getCheckedThreads(selected).length);
  }, [selected]);

  useEffect(() => {
    setSelected({});
  }, [project, setSelected]);

  const handleJoinThreads = () => {
    const threads = getCheckedThreads(selected);
    if (!threads.length) {
      return;
    }

    const disjoinThreads = async (
      story: string,
      threads: string[],
      dryrun?: boolean
    ) => {
      if (threads.length > 0) {
        try {
          if (!dryrun) {
            await httpPost("label", {
              disjoin: { threads: threads, story: story },
            });
          }

          if (onUndo) {
            onUndo();
          }
        } catch (err) {}
      }
    };

    const joinThreads = async (stories: string[]) => {
      const attemptJoin = async (stories: string[]) => {
        try {
          const response = await httpPost("label", {
            join: { stories: stories, dryrun: true },
          });
          const joined = response.data.join;

          if (joined.length > 0) {
            let undone = false;
            const undoDryRun = () => {
              disjoinThreads(joined[0], joined.slice(1), true);
              undone = true;
            };

            const handleCommitJoin = async () => {
              if (!undone) {
                const response = await httpPost("label", {
                  join: { stories: stories, dryrun: false },
                });
                const joined = response.data.join;
                if (joined.length > 0) {
                  const undo = () => {
                    disjoinThreads(joined[0], joined.slice(1), false);
                  };

                  if (joined.length !== stories.length) {
                    let left = [joined[0]];
                    for (let s of stories) {
                      if (!joined.includes(s)) {
                        left.push(s);
                      }
                    }

                    const message = `We could not merge ${left.length} stories. Perhaps, it's too much work to do in one go.`;
                    const retry = () => attemptJoin(left);

                    if (joined.length > 1) {
                      toast.warning(
                        <RetryToastHorizontal
                          message={message}
                          onRetry={retry}
                          onUndo={undo}
                        />
                      );
                    } else {
                      toast.warning(
                        <RetryToastHorizontal
                          message={message}
                          onRetry={retry}
                        />
                      );
                    }
                  }
                }
              }
            };

            const mainStoryId = joined[0];
            const req = { stories: true, story: mainStoryId };
            const resp = await httpPost("stories", req);
            const storyData = resp.data.stories[0];

            toast.info(
              <RetryToastVertical
                message={`Joining ${stories.length} stories!`}
                onUndo={undoDryRun}
              >
                <DarkStoryPreview item={storyData} />
              </RetryToastVertical>,
              {
                onClose: handleCommitJoin,
                pauseOnFocusLoss: false,
                autoClose: 2000,
                icon: false,
              }
            );
          }

          onJoin(joined);
        } catch (err) {}
      };

      await attemptJoin(stories);
      setSelected({});
    };

    joinThreads(threads);
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleJoinThreads}
        edge="start"
        disabled={numSelected < 2}
        sx={{ mr: 2, ...sx }}
      >
        <>
          {numSelected >= 2 && (
            <Badge color="error" badgeContent={numSelected.toString()}>
              <ForumIcon />
            </Badge>
          )}
          {numSelected === 1 && (
            <Badge badgeContent={numSelected.toString()}>
              <ForumIcon />
            </Badge>
          )}
          {numSelected === 0 && <ForumIcon />}
        </>
      </IconButton>
    </>
  );
};

type BulkDeleteProps = {
  onDelete?: (stories: string[]) => void;
  onUndo?: () => void;
  sx?: any;
};

const BulkDelete = ({ onDelete, onUndo, sx }: BulkDeleteProps) => {
  const [selected, setSelected] = useAtom(joinStoriesState);
  const [numSelected, setNumSelected] = useState(0);
  const [httpPost, project] = useHttpPostAndProject();

  useEffect(() => {
    setNumSelected(getCheckedThreads(selected).length);
  }, [selected]);

  useEffect(() => {
    setSelected({});
  }, [project, setSelected]);

  const handleDelete = () => {
    const threads = getCheckedThreads(selected);
    if (!threads.length) {
      return;
    }

    const deleteBulk = async (stories: string[]) => {
      const restoreBulk = async (stories: string[]) => {
        try {
          const response = await httpPost("label", {
            restore: { stories: stories },
          });
          const restored = response.data.restore;
          if (restored.length > 0) {
            setSelected((prev) => {
              let next = prev ? { ...prev } : {};
              for (let d of restored) {
                next[d] = true;
              }
              return next;
            });
          }
          if (onUndo) {
            onUndo();
          }
        } catch (err) {}
      };

      try {
        const response = await httpPost("label", {
          delete: { stories: stories },
        });
        const deleted = response.data.delete;
        if (deleted.length > 0) {
          setSelected((prev) => {
            let res = prev ? { ...prev } : {};
            for (let d of deleted) {
              delete res[d];
            }
            return res;
          });

          let message = "Successfully deleted ";
          if (deleted.length > 1) {
            message += `${deleted.length} stories.`;
          } else {
            message += "1 story.";
          }

          if (onDelete) {
            onDelete(deleted);
          }

          toast.success(
            <RetryToastHorizontal
              message={message}
              onUndo={() => restoreBulk(deleted)}
            />
          );
        }
      } catch (err) {}
    };

    deleteBulk(threads);
  };

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleDelete}
        edge="start"
        disabled={numSelected < 1}
        sx={{ mr: 2, ...sx }}
      >
        <>
          {numSelected > 0 && (
            <Badge color="error" badgeContent={numSelected.toString()}>
              <DeleteIcon />
            </Badge>
          )}
          {numSelected === 0 && <DeleteIcon />}
        </>
      </IconButton>
    </>
  );
};

function ImageToggle() {
  const [toggle, setToggle] = useAtom(imageToggleAtom);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <Tooltip title={toggle ? "Hide pictures" : "Show pictures"}>
      <IconButton
        color="inherit"
        onClick={handleClick}
        edge="start"
        sx={{ mr: 2 }}
      >
        {toggle && <CropOriginalIcon />}
        {!toggle && <PhotoIcon />}
      </IconButton>
    </Tooltip>
  );
}

function DebugToggle() {
  const [toggle, setToggle] = useAtom(debugToggleState);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <Tooltip title={toggle ? "Debug mode on" : "Debug mode off"}>
      <IconButton
        color="inherit"
        onClick={handleClick}
        edge="start"
        sx={{ mr: 2 }}
      >
        <PestControlIcon />
      </IconButton>
    </Tooltip>
  );
}

function LabelToggle() {
  const [toggle, setToggle] = useAtom(labelToggleState);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <Tooltip title={toggle ? "Hide labels" : "Show labels"}>
      <IconButton
        color="inherit"
        onClick={handleClick}
        edge="start"
        sx={{ mr: 2 }}
      >
        {toggle && <TurnedInNotIcon />}
        {!toggle && <TurnedInIcon />}
      </IconButton>
    </Tooltip>
  );
}

function getCategoryToShow(type?: string): MenuCategory {
  if (type === "story") {
    return "Stories";
  } else if (type === "message") {
    return "Artifacts";
  } else {
    return (u.readPageStorage("Home", "Stories") || "Stories") as MenuCategory;
  }
}

function Home({ signOut }: { signOut?: SignOut }) {
  const theme = useTheme();
  const [open, setOpen] = useLocalStorage("AppBarOpened", true);
  const { type, proj, id, ts } = useParams();
  const topicFilter = type === "story" ? id : undefined;
  const messageFilter = type === "message" ? id : undefined;
  const projectFilter = type === "project" || proj ? proj : undefined;
  const [selected, setSelected] = useAtom(selectedCategory);
  const [newLabel, setNewLabel] = useState<string>();
  const [collapseAll, setCollapseAll] = useState(0);
  const [project, setProject] = useAtom(projectPickerState);
  const setReload = useSetAtom(reloadState);
  const setMergedStories = useSetAtom(mergedStoriesState);
  const [drawerWidth, setDrawerWidth] = useLocalStorage(
    "DrawerWidth",
    DEFAULT_DRAWER_WIDTH
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // [211]
    refreshToken(
      () => {},
      () => {
        if (signOut) {
          signOut();
        }
      }
    );
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDataReload = () => {
    setReload((prev) => prev + 1);
  };

  const handleStoriesJoined = (stories: string[]) => {
    setMergedStories(stories);
  };

  const handleCollapseAll = () => {
    setCollapseAll(collapseAll + 1);
  };

  const handleSignOut = () => {
    if (signOut) {
      signOut();
    }
  };

  const handleLabelCreated = useCallback(
    (newLabel: string) => {
      setNewLabel(newLabel);
    },
    [setNewLabel]
  );

  const handleSelectCategory = (cat: MenuCategory) => {
    if (messageFilter || topicFilter) {
      const url = u.getProjectUrl(project);
      window.location.href = url;

      const qualifiedKey = `Home:/${project}${window.location.search}`;
      store(qualifiedKey, cat);
    } else if (!type) {
      setSelected(cat);
      u.writePageStorage("Home", cat);
    }
  };

  const handleLogoClicked = () => {
    const url = project ? u.getProjectUrl(project) : u.getHomeUrl();
    if (window.location.href !== url) {
      window.location.href = url;
    } else {
      handleDataReload();
    }
  };

  const handleFetch = useCallback((changed: boolean) => {
    if (ref && ref.current && changed) {
      setTimeout(() => {
        if (ref && ref.current) {
          ref.current.scrollIntoView({
            behavior: "auto",
            block: "start",
            inline: "start",
          });
        }
      }, 300);
    }
  }, []);

  useEffect(() => {
    let newProject: string = project;
    if (topicFilter || messageFilter || projectFilter) {
      newProject = proj || "";
    }

    if (project !== newProject) {
      setProject(newProject);
    }

    document.title = `topcat | ${project}`;
  }, [project]);

  useEffect(() => {
    setSelected(getCategoryToShow(type));
  }, []);

  const showFilters = () => {
    return (
      selected === "Stories" ||
      selected === "Popular" ||
      selected === "Recent" ||
      selected === "Graph" ||
      selected === "Settings" // because updating queries in settings will result in filters being reload
    );
  };

  const showStoryControls = () => {
    return (
      selected === "Stories" ||
      selected === "Popular" ||
      selected === "Recent" ||
      selected === "Events"
    );
  };

  const showTimeFilters = () => {
    return (
      selected === "Stories" ||
      selected === "Popular" ||
      selected === "Recent" ||
      selected === "Artifacts" ||
      selected === "Graph"
    );
  };

  const handleStoryClicked = (storyId: string) => {
    if (project) {
      const url = u.getStoryUrlFromId(storyId, project);
      window.open(url, "_blank", "noreferrer");
    }
  };

  const handleDrawerResizeMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    const handleMouseUp = () => {
      document.removeEventListener("mouseup", handleMouseUp, true);
      document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = (e: MouseEvent) => {
      const newWidth = e.clientX - document.body.offsetLeft;
      setDrawerWidth(
        lodash.clamp(newWidth, MIN_DRAWER_WIDTH, MAX_DRAWER_WIDTH)
      );
    };

    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <QuickSearch />
      <AppBar position="fixed" open={open} drawerWidth={drawerWidth}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            color="inherit"
            onClick={handleDrawerClose}
            edge="start"
            sx={{ mr: 2, ...(!open && { display: "none" }) }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>

          <Box
            component="img"
            sx={{
              height: 40,
              mr: 3,
              ml: 0,
              mb: 1,
              mt: 1,
              ...(open && { display: "none" }),
            }}
            src="https://stumti-webapp.s3.eu-west-1.amazonaws.com/resources/logo.png"
            onClick={handleLogoClicked}
          />

          {(showStoryControls() ||
            selected === "Graph" ||
            selected === "Artifacts") && (
            <IconButton
              color="inherit"
              onClick={handleDataReload}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ReplayIcon />
            </IconButton>
          )}

          {selected === "People" && <PeopleControls />}

          {showStoryControls() && (
            <>
              <ShowSelectedStoriesButton />
              <IconButton
                color="inherit"
                onClick={handleCollapseAll}
                edge="start"
                sx={{ mr: 2 }}
              >
                <ExpandLessIcon />
              </IconButton>

              <ImageToggle />
            </>
          )}

          {(showStoryControls() || selected === "Artifacts") && <DebugToggle />}

          {showStoryControls() && <LabelToggle />}

          {!topicFilter && !messageFilter && (
            <Box width="20%">
              <ProjectPicker />
            </Box>
          )}
          {showStoryControls() && (
            <>
              <JoinButton
                onJoin={handleStoriesJoined}
                onUndo={handleDataReload}
                sx={{ ml: 1 }}
              />
              <BulkDelete
                onDelete={handleDataReload}
                onUndo={handleDataReload}
              />
            </>
          )}
          <Button
            variant="contained"
            sx={{ ml: "auto" }}
            onClick={handleSignOut}
          >
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        ref={ref}
      >
        <Box
          sx={{
            position: "relative",
            display: "inline-block",
            width: "100%",
            height: "100%",
            overflowX: "clip",
          }}
        >
          <Box
            onMouseDown={(e) => handleDrawerResizeMouseDown(e)}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 100,
              width: "5px",
              height: "100%",
              // backgroundColor: "red",
              cursor: "ew-resize",
            }}
          />

          {true && (
            <>
              <DrawerHeader>
                <Box
                  component="img"
                  sx={{ height: 40, mr: 3, ml: 0, mb: 1, mt: 1 }}
                  src="https://stumti-webapp.s3.eu-west-1.amazonaws.com/resources/logo.png"
                  onClick={handleLogoClicked}
                />
              </DrawerHeader>

              <Divider />
              <SideMenu
                selected={selected}
                setSelected={handleSelectCategory}
              />
              {showTimeFilters() && !topicFilter && !messageFilter && (
                <>
                  <Divider />
                  <TimeFilters />
                </>
              )}

              <LabelsData />
              {showFilters() && (
                <>
                  <Divider />
                  <Box
                    sx={{
                      pr: 2,
                      display:
                        topicFilter || messageFilter ? "none" : undefined,
                    }}
                  >
                    <StoryLabelFilterBox newLabel={newLabel} />
                  </Box>
                </>
              )}
              <PeopleData />
            </>
          )}
        </Box>
      </Drawer>
      <Main open={open} drawerWidth={drawerWidth}>
        <DrawerHeader />
        <ToastContainer style={{ width: "450px" }} />
        {selected === "Stories" && (
          <>
            {true && (
              <Stack
                direction="row"
                sx={{ width: "100%" }}
                justifyContent="flex-end"
              >
                <Box width="100%">
                  <WebStory />
                </Box>
                <Search />
              </Stack>
            )}
            <FetchStories
              collapseAll={collapseAll}
              filterId={topicFilter}
              timestampRef={ts}
              onLabelCreated={handleLabelCreated}
              onFetch={handleFetch}
            />
          </>
        )}
        {selected === "Artifacts" && <RawMessages filterId={messageFilter} />}
        {selected === "Deleted" && (
          <MemoFetchStories
            collapseAll={collapseAll}
            filterId={topicFilter}
            timestampRef={ts}
            deleted={true}
            onFetch={handleFetch}
          />
        )}
        {selected === "Popular" && (
          <MemoFetchStories
            collapseAll={collapseAll}
            filterId={topicFilter}
            timestampRef={ts}
            popular={true}
            onLabelCreated={handleLabelCreated}
            onFetch={handleFetch}
          />
        )}
        {selected === "Recent" && (
          <MemoFetchStories
            collapseAll={collapseAll}
            filterId={topicFilter}
            timestampRef={ts}
            recent={true}
            onLabelCreated={handleLabelCreated}
            onFetch={handleFetch}
          />
        )}
        {selected === "Events" && (
          <MemoFetchStories
            collapseAll={collapseAll}
            filterId={topicFilter}
            timestampRef={ts}
            events={true}
            onLabelCreated={handleLabelCreated}
            onFetch={handleFetch}
          />
        )}
        {selected === "Graph" && (
          <Box sx={{ height: "90vh", width: "100%" }}>
            <GraphView onStoryClicked={handleStoryClicked} />
          </Box>
        )}
        {selected === "Vitals" && (
          <Typography>
            - team analytics
            <br />
            - team dynamics
            <br />
            - who's talking to whom <br />
            - activity charts across channels <br />
            - app statistics and activity (e.g. how many messages in slack
            daily) <br />
          </Typography>
        )}
        {selected === "Settings" && <Settings />}
        {selected === "People" && <People />}
      </Main>
    </Box>
  );
}

function SideMenu({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: (category: MenuCategory) => void;
}) {
  const [todayEvents, setTodayEvents] = useState();
  const reloadData = useAtomValue(reloadState);
  const [httpPost, project] = useHttpPostAndProject();
  const storiesRef = useRef<HTMLDivElement>(null);

  const icons: any = {
    Stories: <AutoAwesomeMosaicIcon />,
    Artifacts: <MailIcon />,
    Deleted: <DeleteIcon />,
    Popular: <FavoriteIcon />,
    Recent: <TourIcon />,
    Events: (
      <Badge
        color="error"
        variant={selected === "Events" ? undefined : "dot"}
        invisible={!todayEvents}
        badgeContent={`${todayEvents}`}
      >
        <ScheduleIcon />
      </Badge>
    ),
    Graph: <ShareIcon />,
    Vitals: <InsightsIcon />,
    Settings: <SettingsIcon />,
    People: <PeopleAltIcon />,
  };

  useEffect(() => {
    const request = async () => {
      try {
        const response = await httpPost("stories", {
          events_today: true,
          local_time: new Date().toString(),
        });
        setTodayEvents(response.data.events_today);
      } catch (err) {}
    };

    if (project) {
      request();
    }
  }, [project, reloadData]);

  useEffect(() => {
    if (selected === "Stories" && storiesRef.current) {
      storiesRef.current.focus();
    }
  }, [selected]);

  return (
    <List>
      {menuCategoriesList.map((text, index) => {
        return (
          <ListItem key={index} disablePadding>
            <ListItemButton
              sx={{ m: 0, ml: 1, p: 0 }}
              selected={text === selected}
              onClick={() => setSelected(text)}
              dense
              disableGutters
              ref={text === "Stories" ? storiesRef : undefined}
            >
              <ListItemIcon>{icons[text]}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography fontWeight={"bold"} fontSize={12}>
                    {text}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

function RawMessages({ filterId }: { filterId?: string }) {
  const [data, setData] = useState<MessageItem[]>();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const timeFilter = useAtomValue(timeFilterState);
  const reloadData = useAtomValue(reloadState);
  const [httpPost, project] = useHttpPostAndProject();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await httpPost("stories", {
        showlog: true,
        period: timeFilter,
      });

      const decompress = async (log: any[]): Promise<MessageItem[]> => {
        const out: MessageItem[] = [];
        for (const msg of log) {
          if (msg.gzip) {
            const obj = await u.decompressObject(msg.gzip);
            delete msg.gzip;
            out.push({ ...msg, ...obj });
          } else {
            out.push(msg);
          }
        }
        return out;
      };

      if (!data || data.length !== response.data.showlog.length) {
        if (!filterId) {
          setData(await decompress(response.data.showlog));
        } else {
          const data = response.data.showlog.filter(
            (item: MessageItem) => item.SK === filterId
          );
          setData(await decompress(data));
        }
        setError(null);
      }
    } catch (err: any) {
      log.debug(err);
      if (!data) {
        setError(err.message);
        setData(undefined);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project) {
      getData();
    }
  }, [reloadData, timeFilter, project]);

  return (
    <Box>
      {isLoading && <div>Loading data...</div>}
      {error && <div>Failed to fetch data </div>}

      {data && (
        <Thread
          messages={data}
          allowReplay={true}
          expand={filterId ? true : false}
        />
      )}
    </Box>
  );
}

const MemoFetchStories = memo(FetchStories);

function AuthStage({ stage }: { stage: string }) {
  switchStage(stage);

  return (
    <Authenticator>
      {({ signOut, user }) => {
        return <Home signOut={signOut} />;
      }}
    </Authenticator>
  );
}

function AppRoutes() {
  const stage = useStage();

  return (
    <Routes>
      <Route path="/" element={<AuthStage stage={stage} />} />
      <Route
        path=":type/:proj/:id?/:ts?"
        element={<AuthStage stage={stage} />}
      />
      <Route path=":proj" element={<AuthStage stage={stage} />} />
    </Routes>
  );
}

function App() {
  const [wasmLoaded, setWasmLoaded] = useState(false);
  useEffect(() => {
    init().then((e) => {
      setWasmLoaded(true);
    });
  });

  return (
    <>
      {wasmLoaded && (
        <Router>
          <AppRoutes />
        </Router>
      )}
    </>
  );
}

function TimeFilters() {
  const alltime = String.fromCodePoint(0x221e);
  const filters = ["1d", "2d", "1w", "1m", alltime];
  const [selected, setSelected] = useAtom(timeFilterState);

  const handleClick = (e: React.MouseEvent<HTMLElement>, item: string) => {
    if (item !== selected) {
      setSelected(item);
    }
  };

  const getColor = (item: string) => {
    if (item !== selected) {
      return "default";
    } else if (item === alltime) {
      return "success";
    }
    return "primary";
  };

  return (
    <Grid container spacing={1} sx={{ m: 0.5 }}>
      {filters.map((item) => (
        <Grid key={item}>
          <Chip
            size="small"
            label={item}
            color={getColor(item)}
            onClick={(e) => {
              handleClick(e, item);
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default App;
