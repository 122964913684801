import exp from "constants";

export const DEFAULT_FONT_SIZE = 13;
export const MIN_FONT_SIZE = 5;
export const MAX_FONT_SIZE = 21;
export const FONT_SIZE = [DEFAULT_FONT_SIZE, MIN_FONT_SIZE, MAX_FONT_SIZE];
export const ICON_FONT_SIZE = 15;
export const MAX_FILE_SIZE_PREVIEW = 9 * 1024 * 1024;
export const MAX_NUMBER_UPLOADED_FILES = 20;

// user configs, see admin.py
export const GITHUB_WHITELIST = "github_whitelist";
export const GITLAB_WHITELIST = "gitlab_whitelist";
export const SLACK_WHITELIST = "slack_whitelist";
export const WHITELISTS = [GITHUB_WHITELIST, GITLAB_WHITELIST, SLACK_WHITELIST];

export const GITHUB_BLACKLIST = "github_blacklist";
export const GITLAB_BLACKLIST = "gitlab_blacklist";
export const SLACK_BLACKLIST = "slack_blacklist";
export const BLACKLISTS = [GITHUB_BLACKLIST, GITLAB_BLACKLIST, SLACK_BLACKLIST];

export const SLACK_VERIFICATION_TOKEN = "slack_verification_token";
export const GITHUB_SECRET = "github_secret";
export const GITLAB_SECRET = "gitlab_secret";
export const WEBHOOK_SECRETS = [
  SLACK_VERIFICATION_TOKEN,
  GITHUB_SECRET,
  GITLAB_SECRET,
];

export const SLACK_API_TOKEN = "slack_api_token";
export const GITLAB_API_TOKEN = "gitlab_api_token";
export const GITHUB_API_TOKEN = "github_api_token";
export const API_TOKENS = [
  SLACK_API_TOKEN,
  GITHUB_API_TOKEN,
  GITLAB_API_TOKEN,
  "slack_browser_token",
  "slack_cookie_token",
];

export const SLACK_SIGNING_SECRET = "slack_signing_secret";

export const READ_ONLY_CONF = ["project", "owner", "created_at"];

export const USER_CONFIGS = [
  ...WHITELISTS,
  ...BLACKLISTS,
  ...API_TOKENS,
  ...WEBHOOK_SECRETS,
  SLACK_SIGNING_SECRET,
  ...READ_ONLY_CONF,
];

export const TEXT_PREVIEW_COLOR = "#d5d5d5";
export const TEXT_PREVIEW_BG = "#505050";

export const JSON_THEME = {
  main: "width: 100%; white-space: pre-wrap; word-break: break-word;",
  error: "width: 100%; white-space: pre-wrap; word-break: break-word;",
  key: "color:#b5daf5;",
  string: "color:yellow;",
  value: "color:#e3c2ff;",
  boolean: "color:#e3c2ff;",
};
