import { useAtomValue } from "jotai";

import { toBase64, toBytes } from "fast-base64";

import { useState, useEffect, Children, cloneElement } from "react";
import { useDownloadFile, useHttpPost, useSilentDownload } from "./hooks";

import { projectPickerState } from "./JotaiAtoms";

import * as u from "./utility";

import { MessageItem } from "./MessageItem";
import { storeFileInCache, readFileFromCache } from "./filecache";

import React from "react";

const mimetype2Ext = (mimetype: string) => {
  if (mimetype == "application/pdf") {
    return "pdf";
  } else if (mimetype.startsWith("text/")) {
    return "txt";
  }
};

type EmbeddedFileProps = {
  fileDescriptor?: {
    name?: string;
    url: string;
    mimetype: string;
    uploaded_file?: File;
  };
  fileObject?: File;
  filetypes?: Set<string>;
  item?: MessageItem;
  children: React.ReactNode;
  encoding?: "base64";
  readOnly?: boolean;
  index?: number;
  onError?: () => void;
};

const getFileContent = async (file: File) => {
  const buffer = await file.arrayBuffer();
  return new Uint8Array(buffer);
};

export function EmbeddedFile({
  fileDescriptor,
  fileObject,
  filetypes,
  item,
  children,
  encoding,
  readOnly,
  index,
  onError,
}: EmbeddedFileProps) {
  const [data, setData] = useState<File | Uint8Array | string | undefined>();
  const [wrongTypeOrError, setWrongTypeOrError] = useState<boolean>(false);
  const [ext, setExt] = useState<string>();
  const projectName = useAtomValue(projectPickerState);

  const downloadFileFromS3 = useDownloadFile();
  const downloadSilentlyForFutureUse = useSilentDownload();
  const httpPost = useHttpPost();

  const downloadFile = async (url: string, ext: string) => {
    const downloadS3 = async (
      url: string
    ): Promise<Uint8Array | string | undefined> => {
      let key = "";
      const hash = await u.sha1(url);

      if (u.getExtFromUrl(url)) {
        // ext is always lower case and in cache we nee the ext from the url verbatum
        key = `${projectName}/${hash}.${u.getExtFromUrl(url)}`;

        if (filetypes && !filetypes.has(ext)) {
          setWrongTypeOrError(true);
          if (onError) {
            onError();
          }
          return undefined;
        }
      } else {
        const metaKey = `${projectName}/${hash}.meta`;
        const bytes = await downloadFileFromS3(metaKey);
        if (!bytes) {
          setWrongTypeOrError(true);
          if (onError) {
            onError();
          }
          return undefined;
        }

        const meta = JSON.parse(bytes.toString());

        let ext = undefined;
        if (meta.type.startsWith("image/")) {
          ext = meta.type.split("/").slice(-1)[0];
        } else if (meta.type.startsWith("text/")) {
          ext = "txt";
        }

        if (
          !filetypes ||
          filetypes.has(meta.type) ||
          (ext && filetypes.has(ext))
        ) {
          setExt(ext);
          key = `${projectName}/${hash}`;
        } else {
          setWrongTypeOrError(true);
          if (onError) {
            onError();
          }
          key = "";
        }
      }

      if (key) {
        const body = await downloadFileFromS3(key);
        if (body && encoding === "base64") {
          return await toBase64(body);
        } else {
          return body;
        }
      }

      return undefined;
    };

    let failed = false;
    while (true) {
      try {
        const body = await downloadS3(url);
        setData(body ? body : undefined);
        if (!body) {
          return;
        }

        const mimetype =
          (fileDescriptor && fileDescriptor.mimetype) ||
          (fileObject && fileObject.type);

        await storeFileInCache(projectName, url, body, mimetype, encoding);

        return;
      } catch (err) {
        if (failed) {
          break;
        }
        failed = true;
      }

      let secondAttemptFailed = false;
      try {
        if (item) {
          const path =
            "downloadlink?url=" +
            encodeURIComponent(url) +
            `&PK=${encodeURIComponent(item.PK)}&SK=${encodeURIComponent(
              item.SK
            )}`;
          await httpPost(path, null);
        } else {
          secondAttemptFailed = true;
        }
      } catch (err) {
        secondAttemptFailed = true;
      }
      if (secondAttemptFailed) {
        setWrongTypeOrError(true);
        if (onError) {
          onError();
        }
        break;
      }
    }
  };

  const readFileLocalOrRemote = async () => {
    let ok = false;

    const preloadedFile: File | undefined =
      fileObject || fileDescriptor?.uploaded_file;

    if (preloadedFile) {
      if (encoding === "base64") {
        try {
          const contents = await getFileContent(preloadedFile);
          setData(await toBase64(contents));
          ok = true;
        } catch (e) {}
      } else {
        const contents = await getFileContent(preloadedFile);
        setData(preloadedFile);
        ok = true;
      }
    }

    if (ok && fileDescriptor && fileDescriptor.url) {
      setTimeout(() => {
        downloadSilentlyForFutureUse(
          fileDescriptor.url,
          u.getExt(fileDescriptor)
        );
      }, 2000);
    }

    if (!ok && fileDescriptor?.url) {
      readFileFromCache(projectName, fileDescriptor.url, encoding)
        .then((doc?: { data: string | Uint8Array }) => {
          setData((prev) => prev || doc?.data);
        })
        .catch((err) => {
          const ext = u.getExt(fileDescriptor);
          downloadFile(fileDescriptor.url, ext);
        });
    }
  };

  useEffect(() => {
    if (!data) {
      readFileLocalOrRemote();
    }
    setExt(
      fileDescriptor
        ? u.getExt(fileDescriptor)
        : fileObject
        ? mimetype2Ext(fileObject.type)
        : undefined
    );
  }, [fileDescriptor, fileObject]);

  const handleError = () => {
    setWrongTypeOrError(true);
    if (onError) {
      onError();
    }
  };

  const renderChildren = (children: React.ReactNode): React.ReactNode => {
    return Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        // we need recursion in here in order to pass props to components
        // withing react fragments likc <><Component></>
        if (child.type === React.Fragment) {
          return renderChildren(child.props.children);
        }
        return cloneElement(child as React.ReactElement<any>, {
          data,
          item,
          onError: handleError,
          readOnly,
          index,
          mimetype: fileObject?.type || fileDescriptor?.mimetype,
          fileName: fileObject?.name || fileDescriptor?.name,
        });
      }
    });
  };

  // useEffect(() => {
  //   console.log(
  //     `EmbeddedFile ${wrongTypeOrError} ${ext} ${
  //       data && data instanceof File ? "data" : ""
  //     } ${data && data instanceof File ? "file" : ""}`
  //   );
  // });

  return (
    <>
      {!wrongTypeOrError && (data || fileObject) && (
        <>{renderChildren(children)}</>
      )}
    </>
  );
}
